import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "../layouts/Navbar";
import { publicLinks } from "./links";
import Footer from "../layouts/Footer";
import WhatsApp from "../components/WhatsApp";

const Home = React.lazy(() => import("../pages/Home"));
const About = React.lazy(() => import("../pages/About"));
const Contact = React.lazy(() => import("../pages/Contact"));
const Pricing = React.lazy(() => import("../pages/Pricing"));
const Safari = React.lazy(() => import("../pages/Safari"));
const Gallery = React.lazy(() => import("../pages/Gallery"));


function BaseRouter() {
  return (
    <>
      <Router>
        <Suspense>
          <Navbar />
          <Routes>
            <Route exact path={publicLinks.Home} element={<Home />} />
            <Route path={publicLinks.About} element={<About />} />
            <Route path={publicLinks.Contact} element={<Contact />} />
            <Route path={publicLinks.Pricing} element={<Pricing />} />
            <Route path={publicLinks.Safari} element={<Safari />} />
            <Route path={publicLinks.Gallery} element={<Gallery />} />
          </Routes>
          <WhatsApp/>
          <Footer />
        </Suspense>
      </Router>
    </>
  );
}

export default BaseRouter;
