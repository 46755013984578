import React from "react";
import { Link } from "react-router-dom";
import { publicLinks } from "../constants/links";
import logo from "../images/logos/logosm.svg";

const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-md sticky-top border-bottom bg-white">
        <div className="container-fluid">
          <Link to={publicLinks.Home} className="navbar-brand">
            <img src={logo} alt="" />
          </Link>

          {/* TODO: add offcanvas */}

          <button
            className="navbar-toggler text-white"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
            aria-controls="collapsibleNavbar" // Add aria-controls attribute
          >
            <span className="navbar-toggler-icon text-white"></span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-end"
            id="collapsibleNavbar"
          >
            <ul className="navbar-nav text-uppercase">
              <li className="nav-item">
                <Link to={publicLinks.Home} className="nav-link">
                  Home
                </Link>
              </li>

              <li className="nav-item">
                <Link to={publicLinks.Safari} className="nav-link">
                  Safaris
                </Link>
              </li>

              <li className="nav-item">
                <Link to={publicLinks.Gallery} className="nav-link">
                  Gallery
                </Link>
              </li>

              <li className="nav-item">
                <Link to={publicLinks.Pricing} className="nav-link">
                  Pricing
                </Link>
              </li>

              <li className="nav-item">
                <Link to={publicLinks.About} className="nav-link">
                  About
                </Link>
              </li>

              <li className="nav-item">
                <Link to={publicLinks.Contact} className="nav-link">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
