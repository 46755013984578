const publicLinks = {
  Home: "/",
  About: "/about-us",
  Contact: "/contact-us",
  Pricing: "/pricing",
  Safari:"/safaris",
  Gallery:"/gallery",
};



export { publicLinks};