import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logos/logo.svg";
import { publicLinks } from "../constants/links";

const Footer = () => {
  return (
    <>
      <div className="container-fluid mt-3">
        <footer
          className="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 border-top"
          style={{ backgroundColor: "#053e29" }}
        >
          {/* Logo */}
          <div className="col mb-3">
            <Link className="d-flex align-items-center link-body-emphasis text-decoration-none">
              <img
                src={logo}
                alt=""
                className="bi me"
                style={{
                  objectFit: "cover",
                }}
              />
            </Link>
          </div>

          {/* Contact */}
          <div className="col mb-3">
            <h5 className="text-white fw-semibold">Contact</h5>
            <hr className="text-white" />
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <Link className="nav-link p-0 text-white">
                  <i className="bi bi-envelope"></i> Email
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link className="nav-link p-0 text-white">
                  <i className="bi bi-twitter-x"></i> Twitter
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link className="nav-link p-0 text-white">
                  <i className="bi bi-facebook"></i> Facebook
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link className="nav-link p-0 text-white">
                  <i className="bi bi-instagram"></i> Instagram
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link className="nav-link p-0 text-white">
                  <i className="bi bi-whatsapp"></i> WhatsApp
                </Link>
              </li>
            </ul>
          </div>

          {/* Links */}
          <div className="col mb-3">
            <h5 className="text-white fw-semibold">Links</h5>
            <hr className="text-white" />
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <Link to={publicLinks.Home} className="nav-link p-0 text-white">
                  Home
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to={publicLinks.Safari}
                  className="nav-link p-0 text-white"
                >
                  Safaris
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to={publicLinks.Gallery}
                  className="nav-link p-0 text-white"
                >
                  Gallery
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to={publicLinks.Pricing}
                  className="nav-link p-0 text-white"
                >
                  Pricing
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to={publicLinks.Contact}
                  className="nav-link p-0 text-white"
                >
                  Contact
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to={publicLinks.About}
                  className="nav-link p-0 text-white"
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to={publicLinks.About}
                  className="nav-link p-0 text-white"
                >
                  Legal
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="https://en.wikipedia.org/wiki/Kenya"
                  target="_blank"
                  className="nav-link p-0 text-white"
                >
                  About Kenya
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="https://www.kws.go.ke/national-parks"
                  target="_blank"
                  className="nav-link p-0 text-white"
                >
                  National Parks
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="https://www.sheldrickwildlifetrust.org/"
                  target="_blank"
                  className="nav-link p-0 text-white"
                >
                  Adopt an Elephant
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to={publicLinks.Pricing}
                  className="nav-link p-0 text-white"
                >
                  Deals and Offers
                </Link>
              </li>
            </ul>
          </div>

          {/* Events and News */}
          <div className="col mb-3">
            <h5 className="text-white fw-semibold">Excursions and Safaris</h5>
            <hr className="text-white" />
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <Link className="nav-link p-0 text-white">Mombasa City</Link>
              </li>
              <li className="nav-item mb-2">
                <Link className="nav-link p-0 text-white">
                  Nairobi City Tour
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link className="nav-link p-0 text-white">
                  Malindi and Gede Ruins
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link className="nav-link p-0 text-white">Balloon Safari</Link>
              </li>
              <li className="nav-item mb-2">
                <Link className="nav-link p-0 text-white">
                  Kilimanjaro Climb
                </Link>
              </li>
            </ul>
          </div>

          {/* date */}
          <div className="col-md-4 mt-3 d-flex">
            <div>
              <p className="text-center text-white">
                &copy; Glorious Safaris Mombasa
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
