import React from "react";
import { Link } from "react-router-dom";

const WhatsApp = () => {
  return (
    <>
      <Link
        className="rounded-circle"
        to="https://wa.me/+254799049789"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          backgroundColor: "#25d366",
          color: "#fff",
          padding: "10px",
          textAlign: "center",
          fontSize: "20px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
        }}
      >
        <i className="bi bi-whatsapp"></i>
      </Link>
    </>
  );
};

export default WhatsApp;
