import BaseRouter from "./constants/Routes";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <BaseRouter/>
  );
}

export default App;
